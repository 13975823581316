:root {
  --primary: #ffc423;
  --secondary: #7c7c7c;
  --light: #b4b6b8;
  --slider-seconde: #3885f8;
  --color: var(--primary) !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--secondary);
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--secondary);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--secondary);
}

label[for] {
  font-size: 14px;
}

.highlight2 {
  background-color:var(--primary) !important;
  color: #ffffff;
}

a {
  color: darkblue !important;
}

body {
  overflow-x: hidden;
  background-color: #f2f4f7;
}

.loader {
  border: 10px solid var(--light);
  border-top: 10px solid var(--primary);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 40vh;
}

.input-group {
  display: flex;
}

.input-group-append {
  display: flex;
  align-items: center;
  /*   margin-left:-1px; */
}

.input-group-text {
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 35px;
  color: #fff;
  padding: 0 10px 0;
  text-align: center;
  white-space: nowrap;
  background-color: var(--secondary);
  border: 1px solid var(--primary);
  border-right: none;
  margin-top: -5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.inputrow {
  width: 110%;
  margin-left: -5%;
}

.inputcolumn1 {
  float: left;
  width: 4%;
  /* box-shadow:4px 0 5px -2px #888888a3; */
}

.inputcolumn2 {
  float: left;
  width: 96%;
  /* overflow-y: scroll; */
}


.column1 {
  float: left;
  width: 40%;
  height: 100vh;
  box-shadow: 4px 0 5px -2px #888888a3;
}

.column2._ov_hide {
  overflow: hidden !important;
}

.column2 {
  float: left;
  width: 60%;
  height: 100vh;
  overflow-y: scroll;
}

.row {
  width: 100vw;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

@font-face {
  font-family: "Aspira";
  src: url("Assets/Fonts/AspiraMedium.otf");
}

input:checked {
  background-color: var(--primary);
}

::-webkit-scrollbar {
  width: 5px;
  background-color: var(--light)
}

::-webkit-scrollbar-thumb {
  width: 5px;
  background-color: var(--primary)
}

#logo {
  width: 10vw;
  height: 3.4vw;
  position: absolute;
  top: 8px;

}

#navheader {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  margin-left: -12px;
}

.btndiv {
  margin-top: 20px;
}

.Mui-selected {
  background: var(--light) !important;
  color: #000;

}

button {
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  padding: 10px 25px;
  margin-bottom: 20px;
  transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
}

button:before {
  position: absolute;
  height: 0;
  width: 0;
  border-radius: 50%;
  background-color: #b4b6b8;
  transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
  z-index: -1;
  content: "";
  top: 0;
}

button:hover:before {
  height: 500%;
  width: 225%;
}

/* Transition to a bigger shadow on hover */
button:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

#applyheader {
  display: inline-block;
  margin-left: 10px;
  position: absolute;
  top: 10px;
  left: 15vw
}

* {
  margin: 0;
  font-family: 'Aspira';

}

.text-white {
  color: black !important
}

.line-sep {
  width: 0;
  height: 26px;
  margin: 10px 0;
  opacity: 0.8;
  border: solid 1px #ffffff;
  margin-right: 0.4rem;
  margin-left: 0.4em
}

.backmom {
  background-image: linear-gradient(to bottom, var(--primary), var(--slider-seconde));
  background-blend-mode: overlay;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.MuiButton-containedPrimary {
  background-color: var(--light) !important;
  color: white !important;
}

.roundicon {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
}

.centerize {
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  text-align: center;
  margin-top: 40vh;
}

.Q {
  font-family: "Aspira", sans-serif;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
}

.AccountTypediv {
  display: inline-block;
  justify-content: center;
  height: 220px;
  width: fit-content;
  border: 3px dashed var(--light);
  border-radius: 10%;
  padding: 20px;
  margin-left: 20px;
  margin-top: 20px;

}

.AccountTypediv:hover {
  border: 3px dashed var(--primary);

}

.AccountTypeCheck {
  display: inline-block;
  align-self: end;
  color: var(--primary);
  /* margin-top: -5px;
    position: absolute;
    margin-left: 10px; */
  display: inline-block !important;
  float: right;
}

.AccountTypeName {
  margin-top: 0px;
}

.AccountTypediv ul {
  list-style: none;
  margin-top: 15px;
  /* margin-left: -40px; */
  text-align: start;
  /* position: absolute; */
  max-width: 220px;
}

.AccountTypediv ul li {
  font-size: 12px;
}

.Active {
  border: 3px dashed var(--primary) !important;
}

.Carddiv {
  display: inline-block;
  justify-content: center;
  height: 200px;
  width: 335px;
  border: 3px dashed var(--light);
  border-radius: 10%;
  padding: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.Carddiv ul {
  list-style: none;
  margin-top: 15px;
  text-align: start;
}

.Carddiv ul li {
  font-size: 12px;
}

.Carddiv img {
  width: 240px;
}

.CardCheck {
  display: inline-block;
  align-self: end;
  color: #fff;
  /* margin-top: -155px; */
  /* position: absolute; */
  /* margin-left: 210px; */
  display: inline-block !important;
  float: right;
}

.Carddiv:hover {
  border: 3px dashed var(--primary);

}

.inputtxt {
  width: 100%;
  /* margin-top: 30px; */
  /* border: none !important; */
  border: 1px solid var(--primary) !important;
  border-radius: 5px;
  /* color: var(--primary); */
  height: 35px;
  font-size: 14px;
  background: transparent;
  padding: 5px 10px;
  margin-bottom: 5px;
  /* text-transform: uppercase; */
}

select {
  font-weight: normal;
  border: none !important;
  border-bottom: 1px solid var(--primary) !important;
  outline: none !important;
}

option {
  font-weight: normal;
}

.inputtxt:focus-visible,
select:focus-visible {
  /* border: none !important; */
  /* border-bottom: 1px solid var(--primary) !important;   */
  outline: none !important;
}

/* ::placeholder { 
  color:var(--primary)
} */

.Qhelper2 {
  width: 100%;
  margin: 5px 0 5px;
  margin-bottom: 10px;
  font-family: "Aspira-Regular", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: left;
  color: #7c7c7c;
  visibility: hidden;
}

input:focus+.Qhelper2 {
  visibility: visible !important;
}

select:focus+.Qhelper2 {
  visibility: visible !important;
}

input[type=radio]:checked+label {
  color: black;
}

input[type=radio]+label {
  color: grey;
}


.Qhelper {
  width: 100%;
  margin: 24px 0 15px;
  font-family: "Aspira-Regular", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: left;
  color: #303134;
}

.headersub {
  margin: 2px 0 48px;
  font-family: "Aspira-Regular", sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  text-align: left;
  color: #fff;
}

.input-label {
  font-family: "Aspira-med", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: black;
  margin-bottom: 20px;
}

/* input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
} */

.NICDiv {
  border: 3px dashed #454545;
  padding: 20px;
  width: fit-content;
  border-radius: 10px;
  margin-top: 30px;
  text-align: center;
}

p,
li,
label {
  font-family: "Aspira-Regular", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.32px;
  color: #303134;
}

.formdiv {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .formdiv {
    margin-top: 20px;
  }

  #footermain {
    width: 100% !important;
    position: relative !important;
    height: fit-content !important;
    /* margin-top: 10px; */
  }

  #footermain ul li {
    color: #3885f8 !important;
  }

  .DynamicModelcls {
    width: 90vw !important;
    height: 80vh !important;

  }

  .RFS-StepperContainer {
    min-width: 90% !important;
    overflow-x: scroll !important;
  }

  .dymodelbody {
    overflow-y: auto;
    height: 65vh;
  }

  #moblogohead {
    display: block !important;
  }

  .column1 {
    display: none;
  }

  .sidebanner {
    display: none !important;
  }

  .column2 {
    width: 100% !important;
  }

  #navheader {
    width: 100% !important;
  }

  .text-white {
    color: black !important
  }

  #logo {
    width: 160px;
    height: 52px;
    position: absolute;
    top: 12px;
    left: 15px;

  }

  #applyheader {
    display: inline-block;
    margin-left: -20px;
    position: absolute;
    top: 18px;
    left: 230px;
  }

  #mainheader {
    height: 12vh !important;
  }

  #logoheader {
    margin-top: 8px !important;
    float: none !important;
    width: 100% !important;
  }

  .line-sep {
    width: 0;
    height: 26px;
    margin: 10px 0;
    opacity: 0.8;
    border: solid 1px #ffffff;
    margin-right: 0.4rem;
    margin-left: 0.4em
  }

  .mobilehide {
    display: none !important;
  }

  .rowmob {
    width: 96% !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
  }

  .steperdiv {
    margin-top: 0vh !important;
  }

  .tabs {
    width: 100vw !important;
    overflow-x: none !important;

  }

  .backmom {
    height: 98vh !important;
    background-image: linear-gradient(to bottom, var(--primary), var(--slider-seconde));
    background-blend-mode: overlay;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .roundicon {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;
  }

  .centerize {
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    margin-top: 200px;
  }

  .Q {
    font-family: "Aspira", sans-serif;
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
  }

  .AccountTypediv {
    display: inline-block;
    justify-content: center;
    height: fit-content;
    width: 80%;
    border: 3px dashed var(--light);
    border-radius: 10%;
    padding: 20px;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-top: 20px;

  }

  .AccountTypediv:hover {
    border: 3px dashed var(--primary);

  }

  .AccountTypeCheck {
    display: inline-block;
    align-self: end;
    color: var(--primary);
    /* margin-top: -5px;
      position: absolute;
      margin-left: 15px; */
    display: inline-block !important;
    float: right;
  }

  .AccountTypeName {
    margin-top: 20px;
    margin-left: 20px;
  }

  .AccountTypediv ul {
    list-style: none;
    margin-top: 15px;
    /* margin-left: -40px; */
    margin-top: 15px;
    text-align: start;
  }

  .AccountTypediv ul li {
    font-size: 12px;
  }

  .Active {
    border: 3px dashed var(--primary) !important;
  }

  .Carddiv {
    display: inline-block;
    justify-content: center;
    height: fit-content;
    width: 80%;
    border: 3px dashed var(--light);
    border-radius: 10%;
    padding: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    margin-left: 20px;

  }

  .Carddiv ul {
    list-style: none;
    margin-top: 15px;
    text-align: start;
  }

  .Carddiv ul li {
    font-size: 12px;
  }

  .Carddiv img {
    width: 240px;
  }

  .CardCheck {
    display: inline-block;
    align-self: end;
    color: #fff;
    /* margin-top: -5px;
      position: absolute;
      margin-left: 170px; */
    display: inline-block !important;
    float: right;
  }

  .Carddiv:hover {
    border: 3px dashed var(--primary);

  }

  .inputtxt {
    width: 100%;
    /* margin-top: 30px; */
    border: none !important;
    border-bottom: 1px solid var(--primary) !important;
    font-size: 14px;
    /* color: var(--primary); */
    /* text-transform: uppercase; */
    margin-left: 10px;
  }

  .Iconcls {
    margin-top: 40px !important;
  }

  .input-group {
    display: flex;
    margin-left: 10px;
  }

  .input-group-append {
    display: flex;
    align-items: center;
    /*   margin-left:-1px; */
  }

  .input-group .inputtxt {
    width: 80% !important;
  }

  .input-group-text {
    display: flex;
    align-items: center;
    font-weight: 400;
    height: 35px;
    color: #fff;
    padding: 0 10px 0;
    text-align: center;
    white-space: nowrap;
    background-color: var(--secondary);
    border: 1px solid var(--primary);
    border-right: none;
    margin-top: -5px;
  }




  select {
    font-weight: normal;
    border: none !important;
    border-bottom: 1px solid var(--primary) !important;
    outline: none !important;
  }

  option {
    font-weight: normal;
  }

  .inputtxt:focus-visible,
  select:focus-visible {
    border: none !important;
    border-bottom: 1px solid var(--primary) !important;
    outline: none !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--primary)
  }

  .Qhelper {
    width: 100%;
    margin: 24px 0 48px;
    font-family: "Aspira-Regular", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    text-align: left;
    color: #303134;
  }

  .headersub {
    margin: 2px 0 48px;
    font-family: "Aspira-Regular", sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    text-align: left;
    color: #fff;
  }

  .input-label {
    font-family: "Aspira-med", sans-serif;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: black;
    margin-bottom: 20px;
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  .NICDiv {
    border: 3px dashed #454545;
    padding: 20px;
    width: fit-content;
    border-radius: 10px;
    margin-top: 30px;
    text-align: center;
  }

  p,
  li,
  label {
    font-family: "Aspira-Regular", sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.32px;
    color: #303134;
  }

  #video {
    height: 480px !important;
    width: 90vw !important;
  }

  .rowmob {
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .row {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }

  .inputcolumn1 .MuiSvgIcon-root {
    margin-top: 40px !important;
  }
}